

/* It means that If browser is not able to load .woff2 file, then load .woff file, if .woff is also not loading then use .otf */
@font-face {
    font-family: 'Toboggan';
    src: url('./assets/Toboggan-Light.woff2') format('woff2'),
         url('./assets/Toboggan-Light.woff') format('woff'),  
         url('./assets/Toboggan-Light.otf') format('opentype');         
}



.hoverX{
    cursor: pointer;
    padding: 0.3rem 0.8rem;
}

.hoverX:hover{
    border: 1px solid gray;
    animation: hoverrr 0.1s ease-in;
    transform: scale(0.97); /* Apply the 95% scale directly */
    animation-fill-mode: forwards; 
}

@keyframes hoverrr {
    from {
      transform: scale(1); /* Start from original scale */
    }
    to {
      transform: scale(0.97); /* Animate to 95% scale */
    }
}






.date-picker-input {
  width: 110px;
  height: 38px;
  border-radius: 4px;
  font-size: 15px; /* Or your desired font size */
  border: 1.5px solid hsl(0, 0%, 80%);
  padding: 0 6px;
}

.date-picker-input2 {
  width: 130px;
  height: 38px;
  border-radius: 4px;
  font-size: 15px; /* Or your desired font size */
  border: 1.5px solid hsl(0, 0%, 80%);
  padding: 0 6px;
}
.date-picker-input3 {
  width: 100px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px; /* Or your desired font size */
  border: 1.5px solid hsl(0, 0%, 80%);
  padding: 0 6px;
}









.cell{
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.cell2T{
  border-bottom: 1px solid #ddd;
  text-align: center;
  padding: 13.75px 0;
}
.cell2XT{
  padding: 7px 0px 15px 0px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.cell2{
  border-bottom: 1px solid #ddd;
  text-align: start;
}
.cell2X{
  padding: 15px 15px 15px 0px;
  border-bottom: 1px solid #ddd;
  text-align: start;
}
.cell2XF{
  padding: 15px 15px 15px 0;
  border-bottom: 1px solid #ddd;
  text-align: start;
}
.cell2XY{
  border-bottom: 1px solid #ddd;
  text-align: center;
}


.cell3{
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
}
.red-cell{
  border-color: red;
}











 .XZ{
  padding-left: 1rem;
 }
@media(min-width:1360px) and (max-width:1540px){
  .XZ{
    padding-left: 0;
   }
}


.leftX{
  width: 16%;
  height: 100%;
  background-color: #f1f1f1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.rightX{
  width: 84%;
  height: 100%;
  background-color: white;
}
@media(min-width:1170px) and (max-width:1360px){
  .leftX{
    width: 20%;
  }
  .rightX{
    width: 80%;
  }
}
@media(min-width:900px) and (max-width:1170px){
  .leftX{
    width: 23%;
  }
  .rightX{
    width: 77%;
  }
}



.POV{
  position: relative;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding-top: 3rem;
  padding-bottom: 4rem;
}




.PAX1{
  width: 80%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PAX2{
  width: 90%;
  height: 30%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
@media(min-width:900px) and (max-width:1200px){
  .PAX1{
    display: none;
  }
  .PAX2{
    display: flex;
  }
  .POV{
    padding-top: 2rem;
  }
}













.modal {
  position: absolute;
  flex-direction: column;
  top: 50%;
  left: 50%;
  translate: -50% -50%;            /* Because of positioning of modal as 50% from top and 50% from left, our modal's left edge will be in the center and modal's upper edge will be in the center, resulting in modal to be in present in bottom-right part of screen 
                                      To bring our modal in center of screen we will use translate: -50% -50%;  -  translate is basically margin, - means negative marging
                                      What it does is the our modal will move up by 50% of modal's height   &   our modal will move left by 50% of modal's width (if width of modal is 400px, then modal will move to left by 200px, if height of modal is 200px, then modal will move to up by 100px) */
  border: 2px solid #ffffff59;
  width: 600px;
  /* scale: 0;                      Scale:0  means hidden, when user will click on button "Share My Profile", then scale will be set to 1(means visible) */
  background: #00000088;       /* Transluscent background for modal */
  backdrop-filter: blur(10px);   /* Blur on Modal */
  color: white;
  z-index: 4;                    /* So that our modal can stay on top of overlay ()   -   Overlay is : Total screen - Modal area    (overlay is layer of blackish sheet on rest of the screen, so that modal looks so attractive) */
  padding: 20px 25px 20px 25px;
  border-radius: 20px;
  transition: 0.5s ease-in-out;
}
.modal-header {
  display: flex;
  flex-direction: row;              /* Because we want to place heading and close button same horizontal level */
  margin-bottom: 25px;
  font-size: 18px;
    
  justify-content: space-between;   /* To place heading and close button on extreme left and right endings */
  align-items: center;              /* To place heading and close button in vertical center */
  padding-bottom: 12px;
  border-bottom: 1px solid #ffffff88;   /* Line below header */
}
.icons-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;                   /* If width of container decreases, then remaining icons are placed in new row because of this */
}
.overlay {
  position: absolute;
  background-color: #5f5f5f83;   /* Color of overlay(which is transluscent) */
  backdrop-filter: blur(10px);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;      /* Here top,bottom,left,right=0;  means that entire screen is covered by it */

  z-index: 3;
  opacity: 1;
  pointer-events: none;          /* Without this property I am not able to click on any button */
  transition: 0.5s ease-in-out;
}


.show-modal{
  display: flex;
}
.hide-modal{
  display: none;
}

.show-overlay{
  display: flex;
}
.hide-overlay{
  display: none;
}






















.modal-delete-rows{
  position: absolute;
  flex-direction: column;
  gap: 1.5rem;
  top: 50%;
  left: 50%;
  translate: -50% -50%;            /* Because of positioning of modal as 50% from top and 50% from left, our modal's left edge will be in the center and modal's upper edge will be in the center, resulting in modal to be in present in bottom-right part of screen 
                                      To bring our modal in center of screen we will use translate: -50% -50%;  -  translate is basically margin, - means negative marging
                                      What it does is the our modal will move up by 50% of modal's height   &   our modal will move left by 50% of modal's width (if width of modal is 400px, then modal will move to left by 200px, if height of modal is 200px, then modal will move to up by 100px) */
  border: 1px solid #f1f1f1;
  width: 360px;
  /* scale: 0;                      Scale:0  means hidden, when user will click on button "Share My Profile", then scale will be set to 1(means visible) */
  background: #ffffff;       /* Transluscent background for modal */
  backdrop-filter: blur(10px);   /* Blur on Modal */
  z-index: 4;                    /* So that our modal can stay on top of overlay ()   -   Overlay is : Total screen - Modal area    (overlay is layer of blackish sheet on rest of the screen, so that modal looks so attractive) */
  padding: 20px 25px;
  border-radius: 8px;
  transition: 0.5s ease-in-out;
}
.overlay-delete-rows {
  position: absolute;
  background-color: #b6b6b683;   /* Color of overlay(which is transluscent) */
  backdrop-filter: blur(8px);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;      /* Here top,bottom,left,right=0;  means that entire screen is covered by it */
  border-radius: 8px;

  z-index: 3;
  opacity: 1;
  pointer-events: none;          /* Without this property I am not able to click on any button */
  transition: 0.5s ease-in-out;
}






.line-chart-responsiveness{  
  width: 100%;                   
  height: 100%;          
  display: flex;
  justify-content: center;
  align-items: flex-start; 
  background-color: white;       
  /* box-shadow: -4px 4px 10px #D3D3D3;                */
}
.line-chart-responsiveness-subset{  
  width: 92%;                   
  height: 90%;          
  display: flex;
  justify-content: center;
  align-items: center; 
  background-color: white;       
  /* box-shadow: -4px 4px 10px #D3D3D3;                */
}



.fontF{
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
}


.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}



.input-field {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #D1D5DB;
  padding: 0.5rem 1rem;
  outline: none; /* Remove default outline */
}

.input-field:focus {
  border: 2px solid #1A7AAF;
}









/* Container for the toggle button */
.toggle-container {
  border-radius: 14px; /* 70% of the original 15px */
  background-color: #ccc;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Styles for the toggle button when toggled */
.toggle-container.toggled {
  /* background-color: #4caf50; */
  background-color: rgb(26, 122, 175)
}

/* The sliding button inside the container */
.toggle-button {
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1.1px; /* Adjust to align with the new height */
  left: 0.8px; /* Adjust to align with the new width */
  transition: transform 0.3s;
}


/* Move the button to the right when toggled */
.toggle-container.toggled .toggle-button {
  transform: translateX(21.2px); /* 70% of the original 30px */
}

@media(min-width:1200px) and (max-width:1400px){
  .toggle-container.toggled .toggle-button {
    transform: translateX(17.78px); /* 70% of the original 30px */
  }
}





/* Container for the toggle button */
.toggle-container2 {
  border-radius: 14px; /* 70% of the original 15px */
  background-color: #ccc;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Styles for the toggle button when toggled2 */
.toggle-container2.toggled2 {
  /* background-color: #4caf50; */
  background-color: rgb(26, 122, 175)
}

/* The sliding button inside the container */
.toggle-button2 {
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1.1px; /* Adjust to align with the new height */
  left: 0.8px; /* Adjust to align with the new width */
  transition: transform 0.3s;
}


/* Move the button to the right when toggled2 */
.toggle-container2.toggled2 .toggle-button2 {
  transform: translateX(17.5px); /* 70% of the original 30px */
}

@media(min-width:1200px) and (max-width:1400px){
  .toggle-container2.toggled2 .toggle-button2 {
    transform: translateX(17.78px); /* 70% of the original 30px */
  }
}





















/* ChipStyles.css */
.chip {
  display: flex;
  align-items: center;
  padding: 8px 22px;
  margin: 5px;
  background-color: #eeeeee;
  border-radius: 10px;
  cursor: pointer;
  font-size: 17px;
  user-select: none;
  border: 1px solid #ddd;
}

.chip.selected {
  background-color: rgb(141, 204, 236);
  color: black;
  border: 1px solid rgb(141, 204, 236);
}









































/*_____________________________________________________ For MMM ___________________________________________________________    */

.mmm-modal-delete-rows{
  position: absolute;
  flex-direction: column;
  display: flex;
  gap: 0.8rem;
  top: 50%;
  left: 50%;
  translate: -50% -50%;            /* Because of positioning of modal as 50% from top and 50% from left, our modal's left edge will be in the center and modal's upper edge will be in the center, resulting in modal to be in present in bottom-right part of screen 
                                      To bring our modal in center of screen we will use translate: -50% -50%;  -  translate is basically margin, - means negative marging
                                      What it does is the our modal will move up by 50% of modal's height   &   our modal will move left by 50% of modal's width (if width of modal is 400px, then modal will move to left by 200px, if height of modal is 200px, then modal will move to up by 100px) */
  border: 1px solid #f1f1f1;
  width: 360px;
  /* scale: 0;                      Scale:0  means hidden, when user will click on button "Share My Profile", then scale will be set to 1(means visible) */
  background: #00000088;       /* Transluscent background for modal */
  backdrop-filter: blur(10px);   /* Blur on Modal */
  color: white;
  z-index: 1;                    /* So that our modal can stay on top of overlay ()   -   Overlay is : Total screen - Modal area    (overlay is layer of blackish sheet on rest of the screen, so that modal looks so attractive) */
  padding: 20px 25px 20px 25px;
  border-radius: 8px;
  transition: 0.5s ease-in-out;
}
.mmm-overlay-delete-rows {
  position: absolute;
  background-color: #5f5f5f83;   /* Color of overlay(which is transluscent) */
  backdrop-filter: blur(10px);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;      /* Here top,bottom,left,right=0;  means that entire screen is covered by it */

  opacity: 1;
  pointer-events: none;          /* Without this property I am not able to click on any button */
  transition: 0.5s ease-in-out;
}



.mmm-cell3{
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
}












.mmm-input-field2 {
width: 100%;
border-radius: 4px;
border: 1px solid #D1D5DB;
padding: 0.5rem 1rem;
font-size: 16px;
outline: none; /* Remove default outline */
color: black;
}  
.mmm-input-field2:focus {
border: 2px solid #1A7AAF;
}













