.header-title{
    font-size: 24px;
    line-height: 36px;
    font-family: "Inter", sans-serif;
}




.search-bar-container{
    width: 297px;
    height: 44px;
    padding: 10px 16px;
    gap: 1rem;
}
.delete-icon{
    height: 46px;
    width: 46px;
}
.add-new-button{
    width: 132px;
    height: 46px;
    padding: 10px 16px;
    font-family: "Inter", sans-serif;
    font-size: 16px;
}
.table-font{
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 15px;
}
.back-navigation-business-setup{           /* AddNew Component */
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    gap: 0.6rem;
}
.add-new-text{
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.gap-between-containers{
    gap: 1rem;
}
.add-new-business-input-container{
    padding: 24px 24px 12px 24px;
    gap: 12px;
}
.input-label{
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 12px;
    padding: 0 4px;
}
.delete-business{
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 16px;
}
.add-new-button{
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    padding: 4px 24px;
}
.cancel-save-container{
    padding: 12px 24px;
}
.error-texts{
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.footer-button{
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 16px;
    padding: 4px 16px;
}
.discard-business-button{
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    padding: 0.4rem 1.2rem;
}
.discard-business-buttons-container{
    gap: 2rem;
    padding: 1.6rem 0 0.6rem 0;
}




.experiment-data-tabs{
    font-family: "Inter", sans-serif;
    font-weight: 400;
    padding: 10px 16px;
    font-size: 18px;
}
.create-exp-button{
    width: 182px;
    height: 46px;
    padding: 10px 16px;
    font-family: "Inter", sans-serif;
    font-size: 16px;   
}


.textarea-description{
    font-family: "Inter", sans-serif;
    font-size: 15px;
    padding: 6px 12px;
}

.browse-text{
    font-family: "Inter", sans-serif;
    font-size: 16px;
}


.input-field2 {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #D1D5DB;
    padding: 0.5rem 1rem;
    font-size: 16px;
    outline: none; /* Remove default outline */
}  
.input-field2:focus {
    border: 2px solid #1A7AAF;
}









@media(min-width:1200px) and (max-width:1400px){
    .header-title{
        font-size: 20px;
        line-height: 30px;
        font-family: "Inter", sans-serif;
    }




    .search-bar-container{
        width: 247px;
        height: 37px;
        padding: 8px 13.3px;
        gap: 0.8rem;
    }
    .delete-icon{
        height: 38px;
        width: 38px;
    }
    .add-new-button{
        width: 110px;
        height: 38px;
        padding: 8px 13.3px;
        font-family: "Inter", sans-serif;
        font-size: 14px;
    }
    .table-font{
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 14px;
    }
    .back-navigation-business-setup{           /* AddNew Component */
        font-family: "Inter", sans-serif;
        font-weight: 500;
        font-size: 14px;
        gap: 0.5rem;
    }
    .add-new-text{
        font-family: "Inter", sans-serif;
        font-weight: 500;
        font-size: 14px;
    }
    .gap-between-containers{
        gap: 0.8rem;
    }
    .add-new-business-input-container{
        padding: 20px 20px 10px 20px;
        gap: 10px;
    }
    .input-label{
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 11px;
        padding: 0 3.3px;
    }
    .delete-business{
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 14px;
    }
    .add-new-button{
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 14px;
        padding: 3.3px 20px;
    }
    .cancel-save-container{
        padding: 10px 24px;
    }
    .error-texts{
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 14px;
    }
    .footer-button{
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 14px;
        padding: 3px 14px;
    }
    .discard-business-button{
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 14px;
        padding: 0.3rem 0.8rem;
    }
    .discard-business-buttons-container{
        gap: 1.6rem;
        padding: 1.3rem 0 0.2rem 0;
    }




    .experiment-data-tabs{
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 15px;
        padding: 8px 13.3px;
    }
    .create-exp-button{    
        width: 154px;
        height: 38px;
        padding: 8px 13.3px;
        font-family: "Inter", sans-serif;
        font-size: 14px;   
    }



    .textarea-description{
        font-family: "Inter", sans-serif;
        font-size: 12.5px;
        padding: 4px 10px;
    }

    .browse-text{
        font-family: "Inter", sans-serif;
        font-size: 14px;
    }


    .input-field2 {
        width: 100%;
        border-radius: 4px;
        border: 1px solid #D1D5DB;
        padding: 0.4rem 0.8rem;
        font-size: 14px;
        outline: none; /* Remove default outline */
    }
}