


.gap-between-logo-and-dropdowns{
    gap: 2rem;
}
.truelift-svg-size{
    width: 95px;
}
.nav-dropdown-styling{
    width:80%;
    padding: 12px 16px;
    margin: 0.6rem 0;
    height: 45px;
}
.title-font{
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.gap-between-icon-and-title{
    gap: 8px;
}
.dashboard-title-styling{
    width:80%;
    padding: 12px 16px;
    margin-bottom: 0.6rem;
    height: 45px;
}
.dropdown-options{
    padding: 0.4rem 1rem 0.4rem 2.4rem;
    gap: 0.3rem;
}
.dropdown-option-internal{
    padding: 12px 16px 12px 15px;
    gap: 8px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
}






@media(min-width:1200px) and (max-width:1400px){
    .gap-between-logo-and-dropdowns{
        gap: 1.4rem;
    }
    .truelift-svg-size{
        width: 88px;
    }
    .nav-dropdown-styling{
        width:88%;
        padding: 10px 13px;
        margin: 0.4rem 0;
        height: 42px;
    }
    .title-font{
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 14.5px;
    }
    .gap-between-icon-and-title{
        gap: 6px;
    }
    .dashboard-title-styling{
        width:88%;
        padding: 11px 13px;
        margin-bottom: 0.4rem;
        height: 42px;
    }
    .dropdown-options{
        padding: 0.2rem 0.5rem 0.2rem 1.4rem;
        gap: 0.2rem;
    }
    .dropdown-option-internal{
        padding: 8px 8px 8px 7px;
        gap: 6px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 14.5px;
    }
}